/* UnderConstruction.css */

.under-construction-container {
  min-height: 100vh;
  background-color: #f8f9fa;
  /* Light background */
  padding: 2rem;
}

.construction-image {
  max-width: 300px;
  animation: bounce 2s infinite;
}

/* Simple Bounce Animation */
@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-20px);
  }

  60% {
    transform: translateY(-10px);
  }
}

/* Responsive Text */
@media (max-width: 576px) {
  .display-4 {
    font-size: 2.5rem;
  }

  .lead {
    font-size: 1.2rem;
  }
}

/* Social Media Icons */
a i {
  color: #495057;
  transition: color 0.3s;
}

a i:hover {
  color: #0d6efd;
}