@import '/src/custom.scss';

.admin-layout {
  .sidebar {
    background-color: #f8f9fa;
    overflow-y: auto;
    position: sticky;
    top: 0;
    height: calc(100vh - 70px); // Ensure it doesn't exceed the viewport
  }

  .logo {
    max-height: 6rem;
    object-fit: contain;
  }

  .sidebar-link {
    .logout{
      color: $danger;
      &:hover {
        background-color: lighten($danger, 10%);
        color: #fff !important
      }
    }

    border-radius: 6px;
    &:hover {
      background-color: lighten($primary, 10%);
      color: #fff
    }
    &.active {
      background-color: $primary;
      color: #fff !important;
      border-radius: 6px;
    }
  }

  .content {
    overflow-y: auto;
  }

  .fixed-bottom {
    z-index: 1050;
  }
}
