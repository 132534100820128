html {
  scroll-behavior: smooth;
}
/* Global CSS or Home-specific CSS */
body {
  overflow-x: hidden !important; /* Prevent horizontal scrolling */
}

/* a#profile-dropdown.dropdown-toggle.nav-link::after {
  display: none;
} */

main {
  text-align: center;
  vertical-align: center;
  line-height: 100vh;
}

.unp-navbar-expanded {
  padding: 1rem 0;
  transition: padding 0.3s ease;
}

.unp-navbar-scrolled {
  padding: 0.5rem 0;
  transition: padding 0.3s ease;
}

.unp-navbar.sticky-top {
  background: #fff;
}

/* Navbar scrolled effect */
.navbar-scrolled {
  border: 0 !important;
  margin-bottom: 62.9
}

@media (max-width: 991.98px) {
  .search-bar-container {
    will-change: transform;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1022; /* Higher than 1020 from navbar */
    background-color: #fff; /* Ensure a solid background */
  }
  .filter-bar-container {
    will-change: transform;
    position: fixed;
    top: 80px; 
    left: 0;
    width: 100%;
    z-index: 1021; /* Just below search bar but above navbar */
    background-color: #fff;
    overflow: hidden !important; /* Prevent horizontal scrolling */
  }
  .main-content {
     padding-top: 220px; 
     padding-bottom: 90px !important;
  }
  .main-content-top {
    padding-top: 220px;
  }
  .main-content-bottom {
    padding-bottom: 90px !important; /* Adjust based on the height of your bottom navbar */
  }

  .container-fluid {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media (min-width: 991.98px) {
  .unp-showcase-grid-container {
    padding-top: 180px;
  }

  .search-bar-container input, .search-bar-container svg {
    pointer-events: auto; /* The input itself can still be clicked/focused */
  }

  .search-bar-container {
    will-change: transform;
    position: fixed;
    top: 70;
    width: 100%;
    z-index: 1022; /* Higher than 1020 from navbar */
    background-color: #fff; /* Ensure a solid background */
    pointer-events: none; /* This means the container doesn’t receive clicks */
  }

  .filter-bar-container {
    will-change: transform;
    position: fixed;
    top: 130px; /* Adjust based on search bar height */
    width: 100%;
    z-index: 1021; /* Just below search bar but above navbar */
    background-color: #fff;
  }
  /* Adjust transforms if needed */
  .search-bar-container.scrolled {
    transform: translateY(-70px) scale(0.85) !important;
    transition: 0.5s ease;
    background-color: transparent !important;
  }

  .filter-bar-container.scrolled {
    transform: translateY(-71px);
    transition: 0.5s ease;
  }
}

.filter-bar-nav-link {
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
}

.scrollable-content {
  padding-bottom: calc(56px + 60px); 
  overflow-y: auto; 
  height: calc(100vh - 70px); 
}