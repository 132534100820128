
// Define custom variables
$unpbackground: #D1CFFF;
$contrast: #38F9B9;

// Override Bootstrap defaults
$primary: #8137FF; // UNP
// $primary: #dc3545; // Kasasa
$secondary: #3C2089; // Your new secondary color
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;

// Generate utilities for custom colors
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "contrast": $contrast,
  "unpbackground": $unpbackground,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

// Import Bootstrap
@import '~bootstrap/scss/bootstrap';
